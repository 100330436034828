const CA = `\
-----BEGIN CERTIFICATE-----
QhqpDgefoya2SU+BKtE+eQU2FsdGVkX18Z1H6Tm2OB8+Xw9ltISwPVmt0twrUZ6jGUEhJC1noOhybeUHlDvoe2AgGq2DvVk+CUMFjP4a84UptWO4XrwosXzpHMtgyGuF1tUmlbuC+iFnmwAvWnadtx9nMnEi4teSGMFNx4oxUpJgfexBejc9IF9Ls/phl9jVI=
Yyodt1dlzX2y6MoVY1sVWwU2FsdGVkX1+0KAMa1uUKnncso9e2/S9duhooqfaYZmkRFBg4bkM4kaiqj6sCiM3+U00bNR/EWa8FwgX106aav+u1VoLAKGTgdgJWXIqKwQw7jT8RX8BvD37SkotNpaJ77MZOgIiDuNJ5vHS7Xua8/Mvv+mL3+6B03ZwXKCQD8lM=
-----END CERTIFICATE-----`

// "localhost",
// appkey "5a2364b5-7b38-fd6f-1f43-77c9a51a1f53",
// appsecret "5a2364b5-7b38-fd6f-53ba-555ae359a928",
// ds "1234567890abcdef"

const Settings = {
  simulation: false,
  tk: 'portoken',
  ah: '/v2',
  // ak: ['please', 'contact', 'joe#wzhu.net'].join('-'),
  // as: ['for', 'a', 'license'].join('-'),
  // ds: ['all', 'rights', 'reserved'].join('-')
  ak: '5a2364b5-7b38-fd6f-1f43-77c9a51a1f53',
  as: '5a2364b5-7b38-fd6f-53ba-555ae359a928',
  ds: '1234567890abcdef'
}

const AMapSettings = {
  key: 'xxxxxxx', // 申请好的Web端开发者Key，首次调用 load 时必填
  version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  token: '_AMap_Token'
  // plugins: ['AMap.AutoComplete'],
  // plugins:['AMap.ToolBar','AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Geocoder'],  // 需要使用的的插件列表，如比例尺'AMap.Scale'等
}

const Enums = {
  'user_types': [{ value: 0, label: '请刷新以重新加载' }], //用户类型
  'auth_types': [{ value: 0, label: '请刷新以重新加载' }], //账户类型
  'event_types': [{ value: 0, label: '请刷新以重新加载' }], //活动类型
  'ticket_types': [{ value: 0, label: '请刷新以重新加载' }], //票务类型
  'institution_types': [{ value: 0, label: '请刷新以重新加载' }], //机构类型
  'institution_levels': [{ value: 0, label: '请刷新以重新加载' }], //机构级别
  'collaborator_levels': [{ value: 0, label: '请刷新以重新加载' }], //合作伙伴级别
  'manga_tags': [{ value: 0, label: '请刷新以重新加载' }], // 漫画标签
  'entry_statuses': [{ value: 0, label: '请刷新以重新加载' }], //作品状态
  'entry_levels': [{ value: 0, label: '请刷新以重新加载' }], //作品级别
  'entry_groups': [{ value: 0, label: '请刷新以重新加载' }], //作品赛道
  'entry_tracks': [{ value: 0, label: '请刷新以重新加载' }], //作品赛道
  'file_statuses': [{ value: 0, label: '请刷新以重新加载' }], //文件状态
  'entry_ctypes': [{ value: 0, label: '请刷新以重新加载' }] //作品用户类型
}

const Categories = {
  'entry': [{ value: 0, label: '请刷新以重新加载' }]
}

export { Settings, AMapSettings, CA, Enums, Categories }
