
// import { Skeleton } from './Settings';
// import { formatTimestampToDate } from './Utils';
import Api from '../utils/api';


export const signin = async (params) => {
    // let params ={loginid, password, country};
    // console.log('GET: /mauth/signin', params);

    return await Api.get('/mauth/signin', params).then((res) => {
        return res;
    })
}

export const signup = async (params) => {
    // let params ={loginid, password, country};
    // console.log('GET: /mauth/signup', params);

    return await Api.post('/auth/signup', params).then((res) => {
        return res;
    })
}