import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    // Layout,
    //  Button,
    //  Form,
    //  Input,
    message
} from 'antd';

import { useTranslation } from 'react-i18next';
import { signin } from '../../api/mauth';

import background from "../../assets/auth/bg.jpg";
// import polyglobe  from '../../assets/auth/polyglobe.png';
//heroglobe.png
import user from '../../assets/auth/user.svg';
import lock from '../../assets/auth/lock.svg';

import "./Signin.css";

function Signin({ setToken }) {

    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [password, setPassword] = useState('');

    const { t } = useTranslation();


    const onFinish = () => {
        const params = {username, password, type:2};

        if (!username) {
            message.error(t('auths.invalid_username'));
            return;
        }
        if (!password) {
            message.error(t('auths.invalid_password'));
            return;
        }

        signin(params).then((res) => {
            if (res.code === 0 && res.token) {
                localStorage.setItem('username', username);
                setToken(res.token);
                message.success(t('auths.signin_success'));
            } else {
                message.error(res.msg);
            }
        }).
        catch((err) => {
            message.error(err);
        });
    };

    return (
        // body {height: 100vh;background:linear-gradient(144deg,#af40ff,#5b42f3 50%,#00ddeb);}
        <div className="bgcover" style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}>
        <div className="page-login">
            <div className="content">
                <div className="text">{t('auths.title')}</div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="field">
                        <input required="" name="username" type="text" className="input" defaultValue={username} placeholder={t('placeholder.input') + t("auths.username")} onKeyUp={(e) => setUsername(e.target.value)} />
                        <span className="span"><img src={user} /></span>
                        <label className="label">Email or Phone</label>
                    </div>
                    <div className="field">
                        <input required="" name="password" type="password" className="input" placeholder={t('placeholder.input') + t("auths.password")} onKeyUp={(e) => setPassword(e.target.value)} />
                        <span className="span"><img src={lock} /></span>
                        <label className="label">Password</label>
                    </div>
                    <button className="button" onClick={onFinish} >{t('auths.signin')}</button>
                    
                </form>
            </div>
        </div>
        </div>
    );
}

Signin.propTypes = {
    setToken: PropTypes.func.isRequired
};

export default Signin;
