import React from 'react'
import PropTypes from 'prop-types'
// import { Link, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Layout, Dropdown, Button } from 'antd';
import { FaIndent, FaOutdent } from 'react-icons/fa';

import {getCommunity} from '../api/community';


// import {
//   MenuUnfoldOutlined,
//   MenuFoldOutlined,
// }
// from '@ant-design/icons';


 function Navbar(props) {

  const { Header } = Layout;
  const  [t,i18n] = useTranslation();
  const [title, setTitle] = React.useState(t("title"));

  const menuLanguages = [
      { label: '简体中文', key: 'zh', },
      { label: 'English', key: 'en', },
      { label: 'Espanol', key: 'es', },
  ];
  // const getOpMenu = () => (
  //   <Menu
  //     onClick={handleMenuClick}
  //     items={[
  //       { label: '简体中文', key: 'zh', },
  //       { label: 'English', key: 'en', },
  //       { label: 'Espanol', key: 'es', },
  //     ]}
  //   />
  // );

  const getLanguageLabel = () => {
    switch(i18n.language) {
      case 'en': return 'English';
      case 'es': return 'Español';
      case 'zh': 
      default  : return '简体中文';
    }
  }

  // const [visible, setVisible] = useState(false);

  const handleMenuClick = (e) => {
    console.log('click', e);
    i18n.changeLanguage(e.key);
  };


  React.useEffect(() => {
    getCommunity().then((res) => {
      setTitle(res.fullname);
    });
  }, []);

  return (
    //style={{padding: props.collapsed ?  "0px calc(" + props.collapsedWidth  + "px / 2 - 8px)" : "0 24px"}}
    <Header className="site-navbar" theme={props.theme == 'dark' ?  'light' : 'dark'}>

        <Button
            className="ant-siderbar-trigger"
            size="small" 
            type={props.collapsed ? "primary" : "text"}
            icon={props.collapsed ? <FaIndent /> : <FaOutdent />}
            onClick={props.toggleCollapsed}
            style={{ color: "white" }}
          />

        <span className="site-logo">{title} </span>
        
        <Dropdown menu={{items:menuLanguages, onClick:handleMenuClick}} trigger={['click']} placement="bottomRight" >
          <Button size="small"  type="primary" style={{ color: "white" }}> {getLanguageLabel()} </Button>
        </Dropdown>
      
    </Header>
  )
}


Navbar.propTypes = {
  collapsed: PropTypes.bool,
  collapsedWidth: PropTypes.number,
  toggleCollapsed: PropTypes.func,
  theme: PropTypes.string,
}

export default Navbar;