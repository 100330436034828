import React, {useEffect} from 'react';
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  // useParams,
} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import "./i18n/index.js";
import { ConfigProvider, theme, App as AntApp } from 'antd';

import useToken from './utils/useToken.js';
import { validLicenseSettings } from './utils/api.js';
import { Enums,Categories } from './utils/settings.js';
import { getEnums } from './api/community.js';
import { getCategories } from './api/category.js';


import zh from 'antd/lib/locale/zh_CN';
import en from "antd/lib/locale/en_US";
import es from "antd/lib/locale/es_ES";

import 'dayjs/locale/zh-cn';

// import "antd/dist/antd.min.css";
// import "antd/dist/antd.compact.min.css";


import LayoutFrame from "./layout/Frame";
import Signin from "./pages/Auth/Signin";
import Home   from "./pages/Home";
// const Home          = React.lazy(() => import("./pages/Home"));

import "./App.css"

//pages

// const Errors        = React.lazy(() => import("./pages/Errors"));
// const About         = React.lazy(() => import("./pages/About"));
// const Test          = React.lazy(() => import("./pages/Test"));

const People        = React.lazy(() => import("./pages/People"));
const Users         = React.lazy(() => import("./pages/Users"));
// const Companies     = React.lazy(() => import("./pages/Companies"));
// const Articles      = React.lazy(() => import("./pages/Articles"));
const Events        = React.lazy(() => import("./pages/Events"));
// const Hotels        = React.lazy(() => import("./pages/Hotels"));
// const Settings      = React.lazy(() => import("./pages/Settings"));
// const Albums        = React.lazy(() => import("./pages/Albums"));
// const Folders       = React.lazy(() => import("./pages/Folders"));
// const Sites         = React.lazy(() => import("./pages/Sites"));
// const Brands        = React.lazy(() => import("./pages/Brands"));

// const Tuttos        = React.lazy(() => import("./pages/Tuttos"));
const Institutions  = React.lazy(() => import("./pages/Institutions"));
const Collaborators = React.lazy(() => import("./pages/Collaborators"));
// const Mangas        = React.lazy(() => import("./pages/Mangas"));

window._AMapSecurityConfig = {
  serviceHost:'//api.betasys.cn/_AMapService',
}


validLicenseSettings();

const App = () => {


  const {i18n} = useTranslation();
  const { token, setToken } = useToken();


  const getLanguage = () => {
    switch (i18n.language) {
      case "zh": return zh;
      case "en": return en;
      case "es": return es;
      default  : return zh;
    }
  }

  useEffect(() => {
    validLicenseSettings();

    getEnums().then((res) => {
      for (let [key, value] of Object.entries(res)) {
        Enums[key] = value;
      }
    });

    getCategories({ filter: { type: 'events' } }).then(res => {
      Categories.entry = res.list;
    });

    document.title = i18n.t('title');
  }, [i18n]);



  if (!token) {
    return <Signin setToken={setToken} />
  }



  const tplTheme = {
    token: {
      colorPrimary: '#1890ff',
      borderRadius: 4,
      wireframe: true,
      "fontSize": 15,
    },

    //5.0 版本中默认提供三套预设算法，分别是默认算法 theme.defaultAlgorithm 、暗色算法 theme.darkAlgorithm 和紧凑算法 theme.compactAlgorithm
    // algorithm: theme.defaultAlgorithm,
    algorithm: theme.compactAlgorithm,

    // components: {}
  };


  // 检查屏幕大小 设置 tplTheme.algorithm

  // const width = window.innerWidth;
  // if (width < 768) {
  //   tplTheme.algorithm = theme.defaultAlgorithm;
  // } else {
  //   tplTheme.algorithm = theme.compactAlgorithm;
  // }




  return (
    <ConfigProvider locale={getLanguage()} theme={tplTheme}>
      <AntApp>
      {/* <Router basename={process.env.PUBLIC_URL}> */}
      <Router>
        <Routes>
          <Route path="/*" element={<LayoutFrame />}>
            <Route index                 element={<Home />} ></Route>
            <Route path="home/*"         element={<Home />} >   </Route>
            {/* <Route path="about/*"        element={<About />} >  </Route> */}
            {/* <Route path="test/*"         element={<Test />} >  </Route> */}
            <Route path="people/*"       element={<People />} > </Route>
            <Route path="users/*"        element={<Users />} >   </Route>
            {/* <Route path="companies/*"    element={<Companies />} > </Route> */}
            {/* <Route path="articles/*"     element={<Articles />} >  </Route> */}
            <Route path="events/*"       element={<Events />} >  </Route>
            {/* <Route path="hotels/*"       element={<Hotels />} >  </Route> */}
            {/* <Route path="albums/*"       element={<Albums />} >  </Route> */}
            {/* <Route path="folders/*"      element={<Folders />} >  </Route> */}
            {/* <Route path="sites/*"        element={<Sites />} >  </Route> */}
            {/* <Route path="settings/*"     element={<Settings />} >  </Route> */}
            {/* <Route path="errors/*"       element={<Errors />} >  </Route> */}
            {/* <Route path="brands/*"       element={<Brands />} >  </Route> */}

            <Route path="institutions/*" element={<Institutions />} >   </Route>
            <Route path="collaborators/*" element={<Collaborators />} >   </Route>
            {/* <Route path="tuttos/*"       element={<Tuttos />} >  </Route> */}
            {/* <Route path="mangas/*"       element={<Mangas />} >  </Route> */}

            <Route path="*" element={<Navigate to='home' />} ></Route>{/* Not Found */}
          </Route>
        </Routes>
      </Router>
      </AntApp>
    </ConfigProvider>
  );
}

export default App;