import React from 'react';
// import { useOutletContext } from "react-router-dom";
// import PropTypes from 'prop-types'
// import { Calendar, Badge } from 'antd';
// import { useTranslation } from 'react-i18next';
// import Main from '../../components/Layout/Main'

import './index.css'

// import Loader from "../../components/Loader/Spin"

export default function Home() {
  // const { t } = useTranslation();

  // const [frameProps] = useOutletContext();
  // React.useEffect(() => {

  //   var canvas = document.getElementById('canvas');

  //   if (!canvas || !canvas.getContext) {
  //     return false;
  //   }

  //   /********************
  //     Random Number
  //   ********************/

  //   function rand(min, max) {
  //     return Math.floor(Math.random() * (max - min + 1) + min);
  //   }

  //   /********************
  //     Var
  //   ********************/
  //   var offw = frameProps.collapsed ? frameProps.collapsedWidth : frameProps.expandedWidth;
  //   var ctx = canvas.getContext('2d');
  //   var offscreenCanvas = document.createElement('canvas');
  //   var offscreenCtx = offscreenCanvas.getContext('2d');
  //   var X = canvas.width = offscreenCanvas.width = window.innerWidth - offw;
  //   var Y = canvas.height = offscreenCanvas.height = window.innerHeight - 100;
  //   // var mouseX = X / 2;
  //   // var mouseY = Y / 2;
  //   var particles = [];
  //   var ease = 0.3;
  //   var friction = 0.8;

  //   // Value
  //   var textValue = '中国好创意大赛';
  //   var compositionValue = 'lighter'
  //   var randomFontColorValue = 1
  //   var flexibilityValue = 1
  //   var fontColorValue = "#ffffff"
  //   var stepValue = 4;
  //   var fontSizeValue = 120;
  //   var particleSizeValue = 5;
  //   var increaseAngleValue = 3;


  //   /********************
  //     Responsive
  //   ********************/

  //   if (X < 768) {
  //     fontSizeValue = 60;
  //     stepValue = 2;
  //     particleSizeValue = 4;
  //   }

  //   /********************
  //     offscreenCanvas
  //   ********************/
  //   function drawText() {
  //     offscreenCtx.save();
  //     offscreenCtx.fillStyle = fontColorValue;
  //     offscreenCtx.font = fontSizeValue + 'px sans-serif';
  //     offscreenCtx.textAlign = 'center';
  //     offscreenCtx.textBaseline = 'middle';
  //     var t = offscreenCtx.measureText(textValue);
  //     if (t.width > X) {
  //       fontSizeValue--;
  //       drawText();
  //     }
  //     offscreenCtx.fillText(textValue, X / 2, Y / 2);
  //     offscreenCtx.restore();
  //   }


  //   /********************
  //     Particle
  //   ********************/
  //   function Particle(ctx, x, y, r, cr, cg, cb) {
  //     this.ctx = ctx;
  //     this.init(x, y, r, cr, cg, cb);
  //   }

  //   Particle.prototype.init = function (x, y, r, cr, cg, cb) {
  //     this.x = x;
  //     this.y = y;
  //     this.xi = rand(0, X);
  //     this.yi = rand(0, Y);
  //     this.r = r;
  //     this.s = 10;
  //     this.c = {
  //       r: cr,
  //       g: cg,
  //       b: cb,
  //       a: 1
  //     };
  //     this.v = {
  //       x: rand(-5, 5) * Math.random(),
  //       y: rand(-5, 5) * Math.random()
  //     };
  //     this.a = rand(0, 360);
  //     this.rad = this.a * Math.PI / 180;
  //   };

  //   Particle.prototype.draw = function () {
  //     var ctx = this.ctx;
  //     ctx.save();
  //     ctx.globalCompositeOperation = compositionValue;
  //     ctx.fillStyle = 'rgb(' + this.c.r + ', ' + this.c.g + ', ' + this.c.b + ')';
  //     ctx.beginPath();
  //     ctx.arc(this.xi, this.yi, Math.sin(this.rad) < 0 ? -Math.sin(this.rad) * this.r : Math.sin(this.rad) * this.r, 0, Math.PI * 2, false);
  //     ctx.fill();
  //     ctx.restore();
  //   };

  //   Particle.prototype.updatePosition = function () {
  //     this.v.x += (this.xi - this.x) * ease;
  //     this.v.y += (this.yi - this.y) * ease;
  //     this.v.x *= friction;
  //     this.v.y *= friction;
  //     this.xi -= this.v.x;
  //     this.yi -= this.v.y;
  //   };

  //   Particle.prototype.changeColor = function () {
  //     this.c = {
  //       r: rand(0, 255),
  //       g: rand(0, 255),
  //       b: rand(0, 255)
  //     }
  //   };

  //   Particle.prototype.updateParams = function () {
  //     this.a += increaseAngleValue;
  //     this.rad = this.a * Math.PI / 180;
  //   };

  //   Particle.prototype.render = function () {
  //     if (flexibilityValue) this.updateParams();
  //     this.updatePosition();
  //     this.draw();
  //   };

  //   /********************
  //     Animation
  //   ********************/
  //   window.requestAnimationFrame =
  //     window.requestAnimationFrame ||
  //     window.mozRequestAnimationFrame ||
  //     window.webkitRequestAnimationFrame ||
  //     window.msRequestAnimationFrame ||
  //     function (cb) {
  //       setTimeout(cb, 17);
  //     };


  //   /********************
  //     Render
  //   ********************/
  //   function initText(cb) {
  //     var data = offscreenCtx.getImageData(0, 0, X, Y).data;
  //     var p;
  //     for (var i = 0; i < Y; i += stepValue) {
  //       for (var j = 0; j < X; j += stepValue) {
  //         var oI = (j + i * X) * 4 + 3; // fantastic! I can not think of it.
  //         if (data[oI] > 0) {
  //           if (randomFontColorValue) {
  //             p = new Particle(ctx, j, i, particleSizeValue, rand(0, 255), rand(0, 255), rand(0, 255));
  //           } else {
  //             p = new Particle(ctx, j, i, particleSizeValue, data[oI - 3], data[oI - 2], data[oI - 1]);
  //           }
  //           particles.push(p);
  //         }
  //       }
  //     }
  //     if (cb) {
  //       cb();
  //     } else {
  //       return;
  //     }
  //   }

  //   /********************
  //     Render
  //   ********************/
  //   function render() {
  //     ctx.clearRect(0, 0, X, Y);
  //     for (var i = 0; i < particles.length; i++) {
  //       particles[i].render();
  //     }
  //     requestAnimationFrame(render);
  //   }

  //   drawText();
  //   initText(render);


  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [frameProps.mediaSize, frameProps.collapsed]);

  return (
    <h1>Welcome</h1>
    // <div className="body">
    //   <canvas id="canvas">Canvas not supported.</canvas>
    // </div>
  );


}

